// Collapsing wizard options
function collapseWizardGroup(input) {
  var $group = input.parents('.js-wizard-option-group')
  var $option = $group.find('.collapse')

  $group.find('.collapse').collapse('hide')
  $option.collapse('show')
}

// Dayparting quick select
function selectDay($day) {
  var dayName = $day.data('select-day')
  var $dayCheckboxes = $('.js-dayparting-input[data-day="' + dayName + '"]')
  var totalCount = $dayCheckboxes.length
  var selectedCount = $('.js-dayparting-input[data-day="' + dayName + '"]:checked').length

  if (selectedCount < totalCount) {
    $dayCheckboxes.prop('checked', true)
  } else {
    $dayCheckboxes.prop('checked', false)
  }
}

function selectTime($time) {
  var timeName = $time.data('select-time')
  var $timeCheckboxes = $('.js-dayparting-input[data-time="' + timeName + '"]')
  var totalCount = $timeCheckboxes.length
  var selectedCount = $('.js-dayparting-input[data-time="' + timeName + '"]:checked').length

  if (selectedCount < totalCount) {
    $timeCheckboxes.prop('checked', true)
  } else {
    $timeCheckboxes.prop('checked', false)
  }
}

$(document).ready(function() {
  // Wizard steps
  $('.js-select-creative').change(function() {
    var $creative = $(this).parents('.js-creative')

    $('.js-creative').removeClass('cc-creative-selected')

    $creative.addClass('cc-creative-selected')

    $('.js-wizard-proceed').removeClass('disabled')
  });

  // Wizard options groups
  $('.js-wizard-collapse').change(function () {
    var $input = $(this)

    collapseWizardGroup($input)
  })

  // Select day parting
  $('.js-select-day').click(function() {
    selectDay($(this))
  })

  $('.js-select-time').click(function() {
    selectTime($(this))
  })

  // Day part warning
  $('.js-select-time[data-select-time="14-00-18-00"]').click(function() {
    $('.js-alert').toggleClass('js-alert-hidden')
  })
})
