var amaranth = '233, 32, 83'
var pink = '255, 128, 149'
var lilac = '153, 132, 173'
var aqua = '71, 212, 176'
var sky = '18, 184, 225'
var neutral300 = '185, 186, 204'
var neutral400 = '145, 145, 169'
var neutral600 = '74, 71, 107'
var neutral900 = '13, 9, 31'
var white = '255, 255, 255'

/*global Chart */

Chart.defaults.global.defaultFontColor = 'rgba(' + neutral400 + ', 1)';
Chart.defaults.global.defaultFontFamily = '"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"';
Chart.defaults.global.defaultFontSize = 14;
Chart.defaults.global.responsive = true;
Chart.defaults.global.maintainAspectRatio = false;

Chart.defaults.global.tooltips.enabled = false;
Chart.defaults.global.tooltips.mode = 'index';

Chart.defaults.global.elements.line.tension = 0;
Chart.defaults.global.elements.line.borderWidth = 2;

Chart.defaults.global.elements.point.borderColor = 'rgba(' + white + ', 0)';
Chart.defaults.global.elements.point.borderWidth = 0;
Chart.defaults.global.elements.point.radius = 0;
Chart.defaults.global.elements.point.hoverBorderWidth = 0;
Chart.defaults.global.elements.point.hoverRadius = 0;

Chart.defaults.global.elements.rectangle.backgroundColor = 'rgba(' + amaranth + ', 1)';

Chart.defaults.global.legend.display = false;
Chart.defaults.global.legend.position = 'bottom';
Chart.defaults.global.legend.labels.boxWidth = 8;
Chart.defaults.global.legend.labels.fontColor = 'rgba(' + white + ', 1)';
Chart.defaults.global.legend.labels.fontStyle = '500';
Chart.defaults.global.legend.labels.padding = 16;
Chart.defaults.global.legend.labels.usePointStyle = true;


Chart.defaults.scale.display = false;

Chart.defaults.scale.gridLines.color = 'rgba(' + white + ', .05)';
Chart.defaults.scale.gridLines.display = false;

Chart.defaults.scale.ticks.maxRotation = 0;

function drawLineLabel(value, color, label) {
  var options = {
    drawTime: 'afterDatasetsDraw',
    type: 'line',
    mode: 'vertical',
    scaleID: 'x-axis-0',
    value: value,
    borderColor: 'rgba(' + color + ', .75)',
    borderWidth: 2,
    label: label
  }

  return options;
}

function lineLabelOptions(label) {
  var options = {
    content: label,
    enabled: true,
    backgroundColor: 'rgba(0, 0, 0, .9)',
    fontFamily: '"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
    fontStyle: '500',
    xPadding: 6,
    yPadding: 4,
    cornerRadius: 2
  }

  return options;
}

function drawZeroLine(color) {
  var options = {
    drawTime: "beforeDatasetsDraw",
    type: "line",
    mode: "horizontal",
    scaleID: "y-axis-0",
    value: '0',
    borderColor: 'rgba(' + color + ', 0.5)',
    borderWidth: 2,
    borderDash: [2, 2]
  }

  return options;
}

function mutedBoxOverlay(xMin, xMax) {
  var options = {
    drawTime: 'afterDatasetsDraw',
    type: 'box',
    xScaleID: 'x-axis-0',
    xMin: xMin,
    xMax: xMax,
    borderColor: 'rgba(' + neutral900 + ', 0)',
    backgroundColor: 'rgba(' + neutral900 + ', 0.5)'
  }

  return options;
}

$(document).ready(function() {

  // Homepage store footfall
  $('#homeChart').each(function() {
    var homeChartData = {
      labels:[
        '2020-03-13T06:00', '2020-03-13T07:00', '2020-03-13T08:00', '2020-03-13T09:00', '2020-03-13T10:00', '2020-03-13T11:00', '2020-03-13T12:00', '2020-03-13T13:00', '2020-03-13T14:00', '2020-03-13T15:00', '2020-03-13T16:00', '2020-03-13T17:00', '2020-03-13T18:00', '2020-03-13T19:00', '2020-03-13T20:00', '2020-03-13T21:00', '2020-03-13T22:00', '2020-03-13T23:00'
      ],
      datasets: [
        {
          label: 'Store',
          borderColor: 'rgba(' + amaranth + ', 1)',
          backgroundColor: 'rgba(' + amaranth + ', .2)',
          pointBackgroundColor: 'rgba(' + amaranth + ', 1)',
          data: [-12, -15, 12, 3, 7, 16, 21, -11, -19, -32, -33, -35, , , , , , ]
        }
        // ,{
        //   label: 'Mall',
        //   borderColor: 'rgba(' + lilac + ', 1)',
        //   backgroundColor: 'rgba(' + lilac + ', .1)',
        //   pointBackgroundColor: 'rgba(' + lilac + ', 1)',
        //   pointBorderColor: 'transparent',
        //   data: [-1, -3, -12, -13, -15, -2, 3, 16, 17, 29, 20, 12, , , , , , ]
        // }
      ]
    };

    new Chart($(this), {
      type: 'line',
      data: homeChartData,
      options: {
        annotation: {
          annotations: [
            drawZeroLine(pink),
            mutedBoxOverlay('2020-03-13T17:00', '2020-03-13T23:00'),
            drawLineLabel('2020-03-13T17:00', sky),
          ]
        }
      }
    });
  });

  // Campaign uplift
  $('#campaignChart').each(function() {
    var campaignChartData = {
      labels:[
        '2020-02-14T06:00', '2020-02-14T07:00', '2020-02-14T08:00', '2020-02-14T09:00', '2020-02-14T10:00', '2020-02-14T11:00', '2020-02-14T12:00', '2020-02-14T13:00', '2020-02-14T14:00', '2020-02-14T15:00', '2020-02-14T16:00', '2020-02-14T17:00', '2020-02-14T18:00', '2020-02-14T19:00', '2020-02-14T20:00', '2020-02-14T21:00', '2020-02-14T22:00', '2020-02-14T23:00'
      ],
      datasets: [
        {
          label: 'Store',
          borderColor: 'rgba(' + amaranth + ', 1)',
          backgroundColor: 'rgba(' + amaranth + ', .2)',
          pointBackgroundColor: 'rgba(' + amaranth + ', 1)',
          pointBorderColor: 'transparent',
          data: [-12, -5, 5, 10, 7, -19, -25, -21, -25, -26, 6, 21, 35, 38, 39, 25, 9, 5]
        }
        ,{
          label: 'Mall',
          borderColor: 'rgba(' + lilac + ', 1)',
          backgroundColor: 'rgba(' + lilac + ', .1)',
          pointBackgroundColor: 'rgba(' + lilac + ', 1)',
          pointBorderColor: 'transparent',
          data: [-1, -3, -12, -13, -15, -2, 3, 6, 7, 14, 16, 12, 15, 10, 11, 5, -1, -5]
        }
      ]
    };

    new Chart($(this), {
      type: 'line',
      data: campaignChartData,
      options: {
        legend: {
          display: true
        },
        annotation: {
          annotations: [
            drawZeroLine(pink),
            mutedBoxOverlay('2020-02-14T06:00', '2020-02-14T14:00'),
            mutedBoxOverlay('2020-02-14T19:00', '2020-02-14T23:00'),
            drawLineLabel('2020-02-14T14:00', aqua, lineLabelOptions('Start')),
            drawLineLabel('2020-02-14T19:00', pink, lineLabelOptions('End'))
          ]
        }
      }
    });
  });

  // Campaign reduction
  $('#campaignReductionChart').each(function() {
    var campaignReductionChartData = {
      labels:[
        '2020-02-23T06:00', '2020-02-23T07:00', '2020-02-23T08:00', '2020-02-23T09:00', '2020-02-23T10:00', '2020-02-23T11:00', '2020-02-23T12:00', '2020-02-23T13:00', '2020-02-23T14:00', '2020-02-23T15:00', '2020-02-23T16:00', '2020-02-23T17:00', '2020-02-23T18:00', '2020-02-23T19:00', '2020-02-23T20:00', '2020-02-23T21:00', '2020-02-23T22:00', '2020-02-23T23:00',
        '2020-02-24T06:00', '2020-02-24T07:00', '2020-02-24T08:00', '2020-02-24T09:00', '2020-02-24T10:00', '2020-02-24T11:00', '2020-02-24T12:00', '2020-02-24T13:00', '2020-02-24T14:00', '2020-02-24T15:00', '2020-02-24T16:00', '2020-02-24T17:00', '2020-02-24T18:00', '2020-02-24T19:00', '2020-02-24T20:00', '2020-02-24T21:00', '2020-02-24T22:00', '2020-02-24T23:00',
        '2020-02-25T06:00', '2020-02-25T07:00', '2020-02-25T08:00', '2020-02-25T09:00', '2020-02-25T10:00', '2020-02-25T11:00', '2020-02-25T12:00', '2020-02-25T13:00', '2020-02-25T14:00', '2020-02-25T15:00', '2020-02-25T16:00', '2020-02-25T17:00', '2020-02-25T18:00', '2020-02-25T19:00', '2020-02-25T20:00', '2020-02-25T21:00', '2020-02-25T22:00', '2020-02-25T23:00',
        '2020-02-26T06:00', '2020-02-26T07:00', '2020-02-26T08:00', '2020-02-26T09:00', '2020-02-26T10:00', '2020-02-26T11:00', '2020-02-26T12:00', '2020-02-26T13:00', '2020-02-26T14:00', '2020-02-26T15:00', '2020-02-26T16:00', '2020-02-26T17:00', '2020-02-26T18:00', '2020-02-26T19:00', '2020-02-26T20:00', '2020-02-26T21:00', '2020-02-26T22:00', '2020-02-26T23:00',
        '2020-02-27T06:00', '2020-02-27T07:00', '2020-02-27T08:00', '2020-02-27T09:00', '2020-02-27T10:00', '2020-02-27T11:00', '2020-02-27T12:00', '2020-02-27T13:00', '2020-02-27T14:00', '2020-02-27T15:00', '2020-02-27T16:00', '2020-02-27T17:00', '2020-02-27T18:00', '2020-02-27T19:00', '2020-02-27T20:00', '2020-02-27T21:00', '2020-02-27T22:00', '2020-02-27T23:00',
      ],
      datasets: [
        {
          label: 'Store',
          borderColor: 'rgba(' + amaranth + ', 1)',
          backgroundColor: 'rgba(' + amaranth + ', .2)',
          pointBackgroundColor: 'rgba(' + amaranth + ', 1)',
          pointBorderColor: 'transparent',
          data: [
            -12, -11, -5, 1, 4, 6, -5, -8, -9, 12, 5, -1, -6, -9, -16, -25, -10, -6,
            -12, -5, 5, 10, 7, -19, -25, -21, -25, -26, 6, 11, 10, 19, 24, 21, 9, 5,
            -12, -11, -5, 1, 4, 6, -5, -8, -9, 12, 5, -1, -6, -9, -16, -25, -10, -6,
            -12, -5, 5, 10, 7, -19, -25, -21, -25, -26, 6, 15, 10, 11, 36, 25, 9, 5,
            -12, -11, -5, 1, 4, 6, -5, -8, -9, 12, 5, -1, -6, -9, -16, -25, -10, -6,
          ]
        }
        ,{
          label: 'Mall',
          borderColor: 'rgba(' + lilac + ', 1)',
          backgroundColor: 'rgba(' + lilac + ', .1)',
          pointBackgroundColor: 'rgba(' + lilac + ', 1)',
          pointBorderColor: 'transparent',
          data: [
            -1, -3, -12, -13, -15, -2, 3, 6, 7, 14, 16, 12, 15, 10, 11, 5, -1, -5,
            -1, -5, -14, -7, -13, -2, 4, 6, -1, -4, -6, -12, -17, -26, -12, -5, 2, 3,
            -1, -3, -12, -13, -15, -2, 3, 6, 7, 14, 16, 12, 15, 10, 11, 5, -1, -5,
            -1, -5, -14, -7, -13, -2, 4, 6, -1, -4, -6, -12, -17, -16, -12, -5, 2, 3,
            -1, -3, -12, -13, -15, -2, 3, 6, 7, 14, 16, 12, 15, 10, 11, 5, -1, -5,
          ]
        }
      ]
    };

    new Chart($(this), {
      type: 'line',
      data: campaignReductionChartData,
      options: {
        legend: {
          display: true
        },
        annotation: {
          annotations: [
            drawZeroLine(pink),
            mutedBoxOverlay('2020-02-23T06:00', '2020-02-23T09:00'),
            mutedBoxOverlay('2020-02-27T13:00', '2020-02-27T23:00'),
            drawLineLabel('2020-02-23T09:00', aqua, lineLabelOptions('Start')),
            drawLineLabel('2020-02-27T13:00', pink, lineLabelOptions('End'))
          ]
        }
      }
    });
  });

  // Campaign live
  $('#campaignLiveChart').each(function() {
    var campaignLiveChartData = {
      labels:[
        '2020-03-01T06:00', '2020-03-01T07:00', '2020-03-01T08:00', '2020-03-01T09:00', '2020-03-01T10:00', '2020-03-01T11:00', '2020-03-01T12:00', '2020-03-01T13:00', '2020-03-01T14:00', '2020-03-01T15:00', '2020-03-01T16:00', '2020-03-01T17:00', '2020-03-01T18:00', '2020-03-01T19:00', '2020-03-01T20:00', '2020-03-01T21:00', '2020-03-01T22:00', '2020-03-01T23:00'
      ],
      datasets: [
        {
          label: 'Store',
          borderColor: 'rgba(' + amaranth + ', 1)',
          backgroundColor: 'rgba(' + amaranth + ', .2)',
          pointBackgroundColor: 'rgba(' + amaranth + ', 1)',
          pointBorderColor: 'transparent',
          data: [-12, -5, 7, -21, -26, 15, 28, 29, 25, 9, 5, 12, 16, 29]
        }
        ,{
          label: 'Mall',
          borderColor: 'rgba(' + lilac + ', 1)',
          backgroundColor: 'rgba(' + lilac + ', .1)',
          pointBackgroundColor: 'rgba(' + lilac + ', 1)',
          pointBorderColor: 'transparent',
          data: [-12, -15, 3, 6, 16, 12, 15, 10, 11, 5, -1, -5, 6, 9]
        }
      ]
    };

    new Chart($(this), {
      type: 'line',
      data: campaignLiveChartData,
      options: {
        legend: {
          display: true
        },
        annotation: {
          annotations: [
            drawZeroLine(pink),
            mutedBoxOverlay('2020-03-01T06:00', '2020-03-01T15:00'),
            drawLineLabel('2020-03-01T15:00', aqua, lineLabelOptions('Start'))
          ]
        }
      }
    });
  });

  // Campaign spend
  $('#campaignSpend').each(function() {
    var percentageSpent = JSON.parse($(this).attr('data-percentage'));
    var percentageRemaining = 100 - percentageSpent;

    var campaignSpendData = {
      datasets: [
        {
          backgroundColor: ['rgba(' + sky + ', 1)', 'rgba(' + sky + ', .2)'],
          borderColor: 'rgba(' + neutral900 + ', 1)',
          hoverBackgroundColor: ['rgba(' + sky + ', 1)', 'rgba(' + sky + ', .2)'],
          hoverBorderColor: 'rgba(' + neutral900 + ', 1)',
          data: [percentageSpent, percentageRemaining]
        }
      ],
      labels: [
        'Spent',
        'Not Spent'
      ]
    }

    new Chart($(this), {
      type: 'pie',
      data: campaignSpendData,
      options: {
        cutoutPercentage: 90
      }
    });
  });

  // Campaign impressions
  $('#campaignImpressionsChart').each(function() {
    var impressionsValues = JSON.parse($(this).attr('data-impressions'))

    var campaignImpressionsChartData = {
      labels: impressionsValues,
      datasets: [
        {
          label: 'Store',
          borderColor: 'rgba(' + sky + ', 1)',
          backgroundColor: 'rgba(' + sky + ', 1)',
          data: impressionsValues,
          barPercentage: '0.75'
        }
      ]
    };

    new Chart($(this), {
      type: 'bar',
      data: campaignImpressionsChartData,
      options: {

      }
    });
  });

  // Analytics chart
  $('#analyticsChart').each(function() {
    var analyticsChartData = {
      labels:[
        '2020-03-13T07:00', '2020-03-13T08:00', '2020-03-13T09:00', '2020-03-13T10:00', '2020-03-13T11:00', '2020-03-13T12:00', '2020-03-13T13:00', '2020-03-13T14:00', '2020-03-13T15:00', '2020-03-13T16:00', '2020-03-13T17:00', '2020-03-13T18:00', '2020-03-13T19:00', '2020-03-13T20:00', '2020-03-13T21:00', '2020-03-13T22:00', '2020-03-13T23:00', '2020-03-14T00:00'
      ],
      datasets: [
        {
          label: 'Store',
          borderColor: 'rgba(' + amaranth + ', 1)',
          backgroundColor: 'rgba(' + amaranth + ', .2)',
          pointBackgroundColor: 'rgba(' + amaranth + ', 1)',
          data: [-12, -15, 12, 3, 7, 16, 21, -11, -19, -32, -33, -35, , , , , , ]
        }
        ,{
          label: 'Mall',
          borderColor: 'rgba(' + lilac + ', 1)',
          backgroundColor: 'rgba(' + lilac + ', .1)',
          pointBackgroundColor: 'rgba(' + lilac + ', 1)',
          data: [-1, -3, -12, -13, -15, -2, 3, 16, 17, 29, 20, 12, , , , , , ]
        }
      ]
    };

    new Chart($(this), {
      type: 'line',
      data: analyticsChartData,
      options: {
        legend: {
          display: true,
          labels: {
            usePointStyle: true
          }
        },
        scales: {
          xAxes: [{
            display: true,
            gridLines: {
              display: true
            },
            type: 'time',
            time: {
              displayFormats: {
                hour: 'HH:mm'
              },
              stepSize: 3
            }
          }],
          yAxes: [{
            display: true,
            gridLines: {
              display: true,
              zeroLineWidth: 2,
              zeroLineColor: 'rgba(' + pink + ', .5)',
              zeroLineBorderDash: [2, 2]
            }
          }]
        },
        annotation: {
          annotations: [
            mutedBoxOverlay('2020-03-13T18:00', '2020-03-14T00:00'),
            drawLineLabel('2020-03-13T18:00', sky, lineLabelOptions('18:00'))
          ]
        }
      }
    });
  });

  // Campaign Analytics chart
  $('#campaignAnalyticsChart').each(function() {
    var campaignAnalyticsChartData = {
      labels:[
        '2020-02-14T06:00', '2020-02-14T07:00', '2020-02-14T08:00', '2020-02-14T09:00', '2020-02-14T10:00', '2020-02-14T11:00', '2020-02-14T12:00', '2020-02-14T13:00', '2020-02-14T14:00', '2020-02-14T15:00', '2020-02-14T16:00', '2020-02-14T17:00', '2020-02-14T18:00', '2020-02-14T19:00', '2020-02-14T20:00', '2020-02-14T21:00', '2020-02-14T22:00', '2020-02-14T23:00'
      ],
      datasets: [
        {
          label: 'Store',
          borderColor: 'rgba(' + sky + ', 1)',
          backgroundColor: 'rgba(' + sky + ', .2)',
          pointBackgroundColor: 'rgba(' + sky + ', 1)',
          pointBorderColor: 'transparent',
          data: [-12, -5, 5, 10, 7, -19, -25, -21, -25, -26, 6, 21, 35, 38, 39, 25, 9, 5]
        }
        ,{
          label: 'Mall',
          borderColor: 'rgba(' + lilac + ', 1)',
          backgroundColor: 'rgba(' + lilac + ', .1)',
          pointBackgroundColor: 'rgba(' + lilac + ', 1)',
          pointBorderColor: 'transparent',
          data: [-1, -3, -12, -13, -15, -2, 3, 6, 7, 14, 16, 12, 15, 10, 11, 5, -1, -5]
        }
      ]
    };

    new Chart($(this), {
      type: 'line',
      data: campaignAnalyticsChartData,
      options: {
        legend: {
          display: true,
          labels: {
            usePointStyle: true
          }
        },
        scales: {
          xAxes: [{
            display: true,
            gridLines: {
              display: true
            },
            type: 'time',
            time: {
              displayFormats: {
                hour: 'HH:mm'
              },
              stepSize: 3
            }
          }],
          yAxes: [{
            display: true,
            gridLines: {
              display: true,
              zeroLineWidth: 2,
              zeroLineColor: 'rgba(' + pink + ', .5)',
              zeroLineBorderDash: [2, 2]
            }
          }]
        },
        annotation: {
          annotations: [
            mutedBoxOverlay('2020-02-14T06:00', '2020-02-14T14:00'),
            mutedBoxOverlay('2020-02-14T19:00', '2020-02-14T23:00'),
            drawLineLabel('2020-02-14T14:00', aqua, lineLabelOptions('Start')),
            drawLineLabel('2020-02-14T19:00', pink, lineLabelOptions('End'))
          ]
        }
      }
    });
  });
});
